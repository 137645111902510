import {format} from "date-fns"

export interface DateProps {
    value:Date|null|undefined;
}


export function Date({value}: DateProps) {    
    
      if (value==null || value == undefined)
        return "";

    return format(value!, "MM/dd/yyyy");
}