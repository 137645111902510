"use client";

import {
  Content as RadixTabsContent,
  List as RadixTabsList,
  Root as RadixTabsRoot,
  Trigger as RadixTabsTrigger,
  type TabsContentProps as RadixTabsContentProps,
  type TabsListProps as RadixTabsListProps,
  type TabsProps as RadixTabsRootProps,
  type TabsTriggerProps as RadixTabsTriggerProps,
} from "@radix-ui/react-tabs";
import { forwardRef } from "react";
import {
  cardTabsListVariants,
  cardTabsTriggerVariants,
  type CardTabsListVariants,
  type CardTabsTriggerVariants,
} from "./card-tabs.styles";

// -----------------------------------------------------------------------------

export type CardTabsRootRef = React.ElementRef<typeof RadixTabsRoot>;

export interface CardTabsRootProps extends RadixTabsRootProps {}

export { RadixTabsRoot as CardTabs, RadixTabsRoot as CardTabsRoot };

// -----------------------------------------------------------------------------

export type CardTabsListRef = React.ElementRef<typeof RadixTabsList>;

export interface CardTabsListProps
  extends RadixTabsListProps,
    CardTabsListVariants {}

const CardTabsList = forwardRef<CardTabsListRef, CardTabsListProps>(
  ({ className, placement, ...props }, ref) => (
    <RadixTabsList
      {...props}
      className={cardTabsListVariants({ className, placement })}
      ref={ref}
    />
  ),
);

CardTabsList.displayName = "CardTabsList";

export { CardTabsList };

// -----------------------------------------------------------------------------

export type CardTabsTriggerRef = React.ElementRef<typeof RadixTabsTrigger>;

export interface CardTabsTriggerProps
  extends RadixTabsTriggerProps,
    CardTabsTriggerVariants {}

const CardTabsTrigger = forwardRef<CardTabsTriggerRef, CardTabsTriggerProps>(
  ({ className, ...props }, ref) => (
    <RadixTabsTrigger
      {...props}
      className={cardTabsTriggerVariants({ className })}
      ref={ref}
    />
  ),
);

CardTabsTrigger.displayName = "CardTabsTrigger";

export { CardTabsTrigger };

// -----------------------------------------------------------------------------

export type CardTabsContentRef = React.ElementRef<typeof RadixTabsContent>;

export interface CardTabsContentProps extends RadixTabsContentProps {}

const CardTabsContent = forwardRef<CardTabsContentRef, CardTabsContentProps>(
  ({ className, ...props }, ref) => (
    <RadixTabsContent {...props} className={className} ref={ref} />
  ),
);

CardTabsContent.displayName = "CardTabsContent";

export { CardTabsContent };
