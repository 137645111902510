

export interface ColorIndicatorParams {
    color : string|undefined
}

export default function ColorIndicator({color} : ColorIndicatorParams) {

    return (<>
       <div style={ { borderRadius:"50%", display: "inline-block", height: 10, width: 10, backgroundColor: color}  } />
    </>);
}