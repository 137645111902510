"use client";

import * as userProfileService from '#app/_api/user-profile-service';
import { H1 } from "#ui/typography";

export function WelcomeHeader() {
    const result = userProfileService.useFriendlyNameQuery();
    const initialData = { friendlyName: result.data }
    const friendlyNameIsNullOrEmpty = typeof initialData.friendlyName === "string" &&
        initialData.friendlyName.length === 0;
    let welcomeMessage;

    if (friendlyNameIsNullOrEmpty) {
        welcomeMessage = "Welcome";
    }
    else {
        welcomeMessage = "Welcome, " + initialData.friendlyName;
    }

    return (
        <H1 size="h2" className="block border-b border-b-white/20 pb-20px">{welcomeMessage}</H1>
    );
}
