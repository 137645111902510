"use client";

import {
  useFinancialPlansStatusQueryOptions,
  useQuerySuccessMeter,
} from "#app/_api/financial-plans-service";
import { Alert, AlertContent } from "#ui/alert";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogMain,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "#ui/alert-dialog";
import { Button } from "#ui/button";
import { Card, CardContent, CardFooter, CardRootProps } from "#ui/card";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { H2, P } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsExporting from "highcharts/modules/exporting";
import SolidGauge from "highcharts/modules/solid-gauge";
import Image from "next/image";
import { ErrorCard } from "../cards/ErrorCard";
import { PendingCard } from "../cards/PendingCard";

if (typeof Highcharts === "object") {
  HighchartsExporting(Highcharts);
  HighchartsMore(Highcharts);
  SolidGauge(Highcharts);
}

const TextColor1 = "#515151";
const getOptions = (targetLow = 0, targetHigh = 0, value = 0) => {
  return {
    credits: { enabled: false },
    exporting: false,
    title: null,
    chart: {
      backgroundColor: null,
      width: 240,
      height: 180,
      margin: [0, 0, 0, 0],
      spacing: [0, 0, 0, 0],
      plotBackgroundColor: null,
      plotBackgroundImage: null,
      plotBorderWidth: 0,
      plotShadow: false,
      type: "gauge",
    },
    pane: {
      background: [
        {
          backgroundColor: null,
          borderWidth: 0,
        },
      ],
      size: "130%",
      center: ["50%", "66%"],
      startAngle: -120,
      endAngle: 120,
    },
    yAxis: {
      min: 0,
      max: 100,
      tickPositions: [0, targetLow, targetHigh], // set number labels for Confidence Zone range
      tickPosition: "inside",
      lineColor: "transparent",
      tickLength: 0,
      minorTickWidth: 0,
      labels: {
        enabled: false,
        distance: 0,
        format: "{value}",
      },
      plotBands: [
        {
          className: `meter-fill-below`,
          color: "#924D8C",
          from: 1, //  Below Confidence Range
          innerRadius: "70%",
          to: targetLow,
        },
        {
          className: `meter-fill-within`,
          color: "#669933",
          from: targetLow,
          innerRadius: "70%",
          to: targetHigh,
        },
        {
          className: `meter-fill-above`,
          color: "#63c5d5",
          from: targetHigh,
          innerRadius: "70%",
          to: 100,
        },
      ],
    },
    plotOptions: {
      gauge: {
        dataLabels: {
          className: "c-monte-carlo-success-meter__label",
          borderRadius: 0,
          borderWidth: 0,
          verticalAlign: "bottom",
          color: "#555555",
          useHTML: true,

          style: { fontSize: "1.7em", paddingTop: "40px" },

          format:
            '<div style="top: -28px; left: -20px; position: absolute">{y}<span class="c-monte-carlo-success-meter__label-unit">%</span></div>',
        },
        dial: {
          radius: "55%",
          backgroundColor: TextColor1,
          baseWidth: 90,
          baseLength: "0%",
          rearLength: "0%",
        },
        pivot: {
          backgroundColor: "#FFFFFF",
          radius: 50,
          borderColor: "rgba(0,0,0,0.05)",
          borderWidth: 4,
        },
        wrap: false,
      },
    },
    series: [
      {
        animation: {
          duration: 1500,
          easing: "easingFunction",
        },
        data: [value],
        enableMouseTracking: false,
        dataLabels: {
          useHTML: true,
        },
        type: "gauge",
      },
    ],
  };
};

function ProbabilityOfSuccessMeter() {
  const { data } = useQuerySuccessMeter();
  const { targetLow, targetHigh, probabilityOfSuccess } = data || {};
  const chartOptions = getOptions(targetLow, targetHigh, probabilityOfSuccess);
  return (
    <div className="width-full flex flex-row justify-center">
      <HighchartsReact highcharts={Highcharts} options={chartOptions} />
    </div>
  );
}

function Disclaimer() {
  return (
    <AlertDialog>
      <AlertDialogTrigger>
        <Icon
          name="ms-info"
          aria-label="More info"
          size="sm"
          palette="accent"
        />
      </AlertDialogTrigger>
      <AlertDialogPortal>
        <AlertDialogOverlay />
        <AlertDialogContent variant="center-scroll-inner">
          <AlertDialogHeader>
            <AlertDialogTitle>Plan Probability of Success</AlertDialogTitle>
          </AlertDialogHeader>
          <AlertDialogMain>
            <div className="space-y-15px">
              <Alert intent="warning">
                <AlertContent>
                  <b>Disclaimer:</b> The plan probability of success may not
                  reflect your current financial situation. Certain inputs to
                  the calculation are only updated when the report is ran or 360
                  Wealth account errors are addressed. If you have any
                  questions, please reach out to your advisory team.
                </AlertContent>
              </Alert>
            </div>
          </AlertDialogMain>
          <AlertDialogFooter>
            <AlertDialogCancel asChild>
              <Button variant="outline">Close</Button>
            </AlertDialogCancel>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogPortal>
    </AlertDialog>
  );
}

function CardPlanMeter(props: CardRootProps) {
  const { isPending, isError } = useQuerySuccessMeter();

  if (isPending) {
    return <PendingCard {...props} />;
  }

  if (isError) {
    return <ErrorCard {...props} />;
  }

  return (
    <Card {...props}>
      <CardContent className="space-y-17px text-center">
        <H2 size="h6" className="flex items-center justify-center gap-3px">
          <span>Plan Probability of Success</span> <Disclaimer />
        </H2>
        <ProbabilityOfSuccessMeter />
      </CardContent>
      <CardFooter palette="white">
        <LinkButton href="/planning/my-plan" className="w-full">
          See my plan
        </LinkButton>
      </CardFooter>
    </Card>
  );
}

function CardPlanUpdate(props: CardRootProps) {
  return (
    <Card {...props}>
      <div className="bg-marketing-background grid place-content-center rounded-t-xl bg-primary-10 bg-contain bg-right-bottom bg-no-repeat px-30px py-24px">
        <Image
          src="/Images/marketing.png"
          alt="Computer showing background"
          width="220"
          height="130"
          priority
        />
      </div>
      <CardContent className="space-y-20px">
        <div className="space-y-7px">
          <H2 size="h6">Have your finances changed?</H2>
          <P size="sm" palette="neutral-subtle">
            Let your advisor know if your financial situation has changed.
          </P>
        </div>
        <div>
          <LinkButton href="/planning" variant="outline" className="w-full">
            Submit a change
          </LinkButton>
        </div>
      </CardContent>
    </Card>
  );
}

function CardPlanStart(props: CardRootProps) {
  return (
    <Card {...props}>
      <div className="bg-marketing-background grid place-content-center rounded-t-xl bg-secondary-15 bg-contain bg-right-bottom bg-no-repeat px-30px py-24px">
        <Image
          src="/Images/marketing.png"
          alt="Computer showing background"
          width="220"
          height="130"
          priority
        />
      </div>
      <CardContent className="space-y-20px">
        <div className="space-y-7px">
          <H2 size="h6">
            Planning for your future. Let your advisor know if your finances
            have changed.
          </H2>
          <P size="sm" palette="neutral-subtle">
            Your Baird Financial Advisor can help you prioritize and plan so you
            can achieve your goals of tomorrow.
          </P>
        </div>
        <div>
          <LinkButton href="/planning" className="w-full">
            Get Started
          </LinkButton>
        </div>
      </CardContent>
    </Card>
  );
}

export function CardPlanning(props: CardRootProps) {
  const { data, isPending, isError } = useQuery(
    useFinancialPlansStatusQueryOptions(),
  );

  if (isPending) {
    return <PendingCard {...props} />;
  }

  if (isError) {
    return <ErrorCard {...props} />;
  }

  if (data.canUseFinancialPlanning && data.hasVisiblePlans) {
    return <CardPlanMeter {...props} />;
  }

  return (
    <>
      <CardPlanUpdate {...props} />
      <CardPlanStart {...props} />
    </>
  );
}
