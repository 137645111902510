"use client";

import { useJwt } from "#app/(unauthorized)/authentication/useJwt";
import { DocumentInfo, DocumentMetaData } from "#app/_api/all-documents-api";
import { useClientCommunicationsDocumentsQuery } from "#app/_api/client-communications-service";
import { useQueryFinancialPlansDocumentsAll } from "#app/_api/financial-plans-service";
import { useStatementsDocumentsQuery } from "#app/_api/statements-service";
import { getBlob } from "#app/lib/fetchClient";
import { Card, CardContent, CardFooter, CardHeader } from "#ui/card";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { H2, Span } from "#ui/typography";
import { format } from "date-fns";
import { PdfViewerDialog } from "../media/PdfViewerDialog";
import { Account } from "../textFormaters/Account";

type DocumentSource = "statement" | "finplan" | "client-communication";

type DateDocument = {
  type: DocumentSource;
  date: Date;
  doc: DocumentInfo;
  docType: DocumentType;
  accountNumber: string;
};

type DocumentMetaDataType = {
  meta: DocumentMetaData;
  type: DocumentSource;
  docType: DocumentType;
};

const typeToText = {
  'client-communication': 'Client Communication',
  'finplan': 'Financial Plan',
  'statement': 'Statement',
} as const satisfies Record<DocumentSource, string>;

export function CardRecentDocuments() {
  const jwt = useJwt();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  today.setDate(today.getDate() + 1);
  const yearAgo = new Date();
  yearAgo.setHours(0, 0, 0, 0);
  yearAgo.setFullYear(yearAgo.getFullYear() - 1);
  const params = {
    accounts: jwt.accounts,
    beginDate: yearAgo,
    endDate: today,
  };
  //ROUTE: communications/documents
  const {
    data: ccData,
    error: ccError,
  } = useClientCommunicationsDocumentsQuery(params);
  //ROUTE: financialplan/documents
  const {
    data: fpData,
    error: fpError,
  } = useQueryFinancialPlansDocumentsAll(params.beginDate, params.endDate);
  //ROUTE: statements/documents
  const {
    data: stData,
    isError: stIsError,
  } = useStatementsDocumentsQuery(params);

  if (jwt.accounts.length > 17) {
    return null;
  }

  if (ccError || fpError || stIsError) {
    /*We should probably handle this? */
  }

  const allMeta = [
    ...(ccData?.map((x) => {
      return { meta: x, type: "client-communication", docType: x.type, accountNumber: x.accountNumber } as DocumentMetaDataType;
    }) ?? []),
    ...(fpData?.map((x) => {
      return { meta: x, type: "finplan", docType: x.type, accountNumber: x.accountNumber } as DocumentMetaDataType;
    }) ?? []),
    ...(stData?.map((x) => {
      return { meta: x, type: "statement", docType: x.type, accountNumber: x.accountNumber } as DocumentMetaDataType;
    }) ?? []),
  ];

  const docs = allMeta.flatMap((x) =>
    x.meta.docs.map((y) => {
      return { doc: y, date: x.meta.date, type: x.type, docType: x.docType, accountNumber: x.meta.accountNumber } as DateDocument;
    }),
  );

  const docs2 = docs.sort((x, y) => (x.date > y.date ? 1 : -1));

  const allDocs = docs2.slice(0, 5);

  return (
    <div>
      <Card className="">
        <CardHeader palette="gray">
          <H2 size="h6" className="flex items-center gap-10px">
            <Icon name="baird-secure-document" size="baird-md" />
            <span>Recent Documents</span>
          </H2>
        </CardHeader>
        <CardContent className="p-0">
          <ul className="divide-y divide-shade-10">
            {allDocs.map((x) => (
              <li
                key={x.doc.url}
                className="flex gap-15px py-30px pl-38px pr-30px"
              >
                <div className="inline-block h-40px w-40px rounded-md bg-primary-100 p-6px">
                  <Icon
                    name="baird-paperless"
                    palette="white"
                    size="baird-md"
                  />
                </div>
                <div className="flex-1">
                  <Span className="block">{x.doc.desc}</Span>
                  <Span className="block" palette="neutral-subtle">{typeToText[x.type]}</Span>
                  <Span className="block" palette="neutral-subtle">{`${format(x.date, "LLL d, yyyy")}`}</Span>
                  <Span className="block" palette="neutral-subtle"><Account className="text-md" accountNumber={x.accountNumber}/></Span>
                </div>
                <div>
                <PdfViewerDialog fileName={x.doc.desc} queryFn={() => getBlob(x.doc.url.slice(4))} />
                </div>
              </li>
            ))}
          </ul>
        </CardContent>
        <CardFooter>
          <LinkButton className="w-full" href="/documents">
            See all
          </LinkButton>
        </CardFooter>
      </Card>
    </div>
  );
}
