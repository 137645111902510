import { Anchor } from "#ui/link";

export function AnchorSecuritySymbol({ symbol }: { symbol: string }) {
  return (
    <Anchor
      href={`/market-tools/stocks/${symbol}-US`}
      target="_blank"
      rel="noopener noreferrer"
    >
      {symbol}
    </Anchor>
  );
}
