import { getJson } from "#app/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { queryOptions } from "@tanstack/react-query";

export type Portfolio = {
  accounts: Account[];
  totals: Totals;
};

export type Totals = {
  value: number;
  change: number;
  percentChange: number;
  balances: Balances;
};

export type Balances = {
  cashValue: number;
};

export type Account = {
  number: string;
  value: number;
  nickname: string;
  accountType: string;
  percentChange: number;
};

export interface AccountActivity {
  transactions: AccountTransaction[];
  page: number;
  pageCount: number;
  pageSize: number;
}

interface AccountTransaction {
  accountNumber: string;
  accountNickname: string;
  accountType: string;
  tradeDate: string;
  activityName: string;
  activityDescriptionType: string;
  activityType: string;
  desc: string;
  quantity: number;
  price: number;
  amount: number;
  source: string;
  instrument: Instrument | null;
  fundTransferMetadata: FundTransferMetadata | null;
}

interface Instrument {
  name: string;
  securityType: string;
  investmentType: string | null;
  assetClasses: InstrumentAssetAllocation[];
  assetCategories: InstrumentAssetAllocation[];
  symbol: string;
  cusip: string;
  secNbr: string | null;
  canQuote: boolean;
  quote: Quote | null;
  optionInfo: OptionInfo;
}

interface InstrumentAssetAllocation {
  name: string;
  percent: number;
}

interface Quote {
  price: number;
  change: number;
  percentChange: number;
}

interface OptionInfo {
  strikePrice: number | null;
  maturityDate: string | null;
  type: string | null;
}

interface FundTransferMetadata {
  id: string;
  instructionNumber: string;
  submittedDate: string;
  status: "Pending" | "Completed" | "Error";
  remote: RemoteAccount;
  type: "CheckDeposit" | "ACH";
}

interface RemoteAccount {
  accountType: "Checking" | "Loan" | "Savings";
  institutionName: string;
  accountNumberLastFour: string;
  isAliasAccount: boolean;
  statementAccountNumber: string;
}

const GATEWAY_PATH = "pwmportfolios/v1";

export async function getPortfolio(): Promise<Portfolio> {
  const result = await getJson<Portfolio>(`/${GATEWAY_PATH}/Portfolio`);
  return result;
}

export async function getPortfolioRecentActivity({
  startDate,
  acctIndex,
  categoryFilter,
  activitySortValue,
  sortDirection,
}: {
  /** Format: yyyy-MM-dd */
  startDate?: `${number}-${number}-${number}`;
  acctIndex?: number[];
  categoryFilter?:
    | "MoneyMarket"
    | "Money"
    | "Security"
    | "Trade"
    | "IncomeandExpense"
    | "VIPChecking"
    | "VIPCardActivity";
  activitySortValue?:
    | "1"
    | "Date"
    | "Activity"
    | "AssetClassification"
    | "Quantity"
    | "Description"
    | "Type"
    | "Symbol"
    | "Price"
    | "Amount";
  sortDirection?: "0" | "Descending" | "Ascending";
} = {}) {
  const searchParamsFiltered = [
    startDate ? `startDate=${startDate}` : null,
    acctIndex?.length && acctIndex.length > 0
      ? `acctIndex=${acctIndex.join(",")}`
      : null,
    categoryFilter ? `categoryFilter=${categoryFilter}` : null,
    activitySortValue ? `activitySortValue=${activitySortValue}` : null,
    sortDirection ? `sortDirection=${sortDirection}` : null,
  ].filter(Boolean);

  const searchParams =
    searchParamsFiltered.length > 0
      ? "?".concat(searchParamsFiltered.join("&"))
      : "";

  return await getJson<AccountActivity>(
    `/${GATEWAY_PATH}/RecentActivity${searchParams}`,
  );
}

export const portfolioQueryKeys = createQueryKeys("Portfolio", {
  portfolio: {
    queryKey: null,
    queryFn: getPortfolio,
  },
  recentActivity: (
    params?: Parameters<typeof getPortfolioRecentActivity>[0],
  ) => ({
    queryKey: [params],
    queryFn: () => getPortfolioRecentActivity(params),
  }),
});

export function useQueryOptionsPortfolio() {
  return queryOptions(portfolioQueryKeys.portfolio);
}

export function usePortfolioQueryOptions() {
  const defaultData: Portfolio | undefined = undefined;
  return queryOptions({
    queryKey: portfolioQueryKeys.portfolio.queryKey,
    queryFn: (context) =>
      (
        portfolioQueryKeys.portfolio.queryFn(context) as Promise<Portfolio>
      ).catch((_) => defaultData),
    staleTime: 0,
  });
}
