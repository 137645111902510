"use client";

import {
  type Account as PortfolioAccount,
  usePortfolioQueryOptions,
} from "#app/_api/portfolio-service";
import { Account } from "#app/_ui/components/textFormaters/Account";
import { Button } from "#ui/button";
import { type CardRootProps, Card, CardContent, CardHeader } from "#ui/card";
import {
  CardTabs,
  CardTabsContent,
  CardTabsList,
  CardTabsTrigger,
} from "#ui/card-tabs";
import { GainLoss } from "#ui/gain-loss";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import {
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverPortal,
  PopoverTrigger,
} from "#ui/popover";
import { H2, Span } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { ErrorCardContent } from "../cards/ErrorCard";
import { PendingCardContent } from "../cards/PendingCard";
import { formatCurrency } from "../textFormaters/Money";

export function CardMyPortfolio(props: CardRootProps) {
  const { data, isPending, isError } = useQuery(usePortfolioQueryOptions());
  const [isShowingAllAccounts, setShowingAllAccounts] =
    useState<boolean>(false);

  if (isPending) {
    return <PendingCardContent />;
  }

  if (isError || data === undefined) {
    return <ErrorCardContent />;
  }

  const initialItems = data.accounts.sort((x, y) => x.value > y.value? -1 : 1).slice(0, 3);
  const moreItems = data.accounts
    .sort((x) => x.value)
    .slice(3, data.accounts.length);

  const hasMoreItems = moreItems.length > 0;

  return (
    <CardTabs defaultValue="accounts">
      <Card {...props}>
        <CardHeader>
          <H2 size="h6" className="flex items-center gap-10px">
            <Icon name="baird-pie-chart" size="baird-md" />
            <span>My Portfolio</span>
          </H2>
        </CardHeader>
        <div>
          <CardTabsList placement="top-outside">
            <CardTabsTrigger value="accounts">Accounts</CardTabsTrigger>
            <CardTabsTrigger value="account-groups">
              Account Groups
            </CardTabsTrigger>
          </CardTabsList>
        </div>
        <CardContent>
          <CardTabsContent value="accounts" className="space-y-20px">
            <ul className="space-y-20px">
              {initialItems.map((el) => (
                <PortfolioAccountItem key={el.number} data={el} />
              ))}
              {isShowingAllAccounts &&
                moreItems.map((el) => (
                  <PortfolioAccountItem key={el.number} data={el} />
                ))}
            </ul>

            {!isShowingAllAccounts && hasMoreItems ? (
              <Button
                onClick={() => setShowingAllAccounts(true)}
                variant="outline"
                palette="accent"
                className="mx-auto flex max-sm:w-full"
              >
                {`Show ${data.accounts.length === 4 ? "1 more account" : data.accounts.length - 3 + " more accounts"}`}
              </Button>
            ) : null}

            {isShowingAllAccounts ? (
              <Button
                onClick={() => setShowingAllAccounts(false)}
                variant="outline"
                palette="accent"
                className="mx-auto flex max-sm:w-full"
              >
                Hide accounts
              </Button>
            ) : null}
          </CardTabsContent>
          <CardTabsContent value="account-groups">TBD</CardTabsContent>
        </CardContent>
      </Card>
    </CardTabs>
  );
}

function PortfolioAccountItem({ data }: { data: PortfolioAccount }) {
  return (
    <li className="rounded-lg border border-shade-15">
      <div className="flex flex-col gap-y-30px p-30px max-sm:py-30px md:flex-row md:justify-between">
        <div className="flex flex-row gap-15px">
          <Icon
            className="h-40px w-40px rounded-md bg-primary-100 p-10px"
            name="baird-logo"
            palette="white"
            size="baird-md"
          />
          <div className="flex flex-col gap-y-6px">
            <div>
              <Account accountNumber={data.number} multilineWithInfo />
            </div>
          </div>
        </div>

        <div className="flex flex-col items-end gap-y-16px">
          <div>
            <Span className="text-right text-h3">
              {formatCurrency(data.value)}
            </Span>
          </div>
          <div className="flex items-center gap-5px">
            <GainLoss value={data.percentChange} format="percent" />
            <Span palette="neutral-subtle" size="sm">
              Daily Change
            </Span>
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-10px rounded-b-lg border-t border-shade-15 bg-shade-2 px-30px py-15px">
        {/* Desktop render */}
        <LinkButton
          className="text-sm max-sm:hidden"
          variant="outline"
          palette="neutral"
          href="/documents"
        >
          Transfer funds
        </LinkButton>
        <LinkButton
          className="text-sm max-sm:hidden"
          variant="outline"
          palette="neutral"
          href="/documents"
        >
          View investments
        </LinkButton>
        <LinkButton
          className="text-sm max-sm:hidden"
          variant="outline"
          palette="neutral"
          href="/documents"
        >
          View activity
        </LinkButton>
        <LinkButton
          className="text-sm max-sm:hidden"
          variant="outline"
          palette="neutral"
          href="/documents"
        >
          View documents
        </LinkButton>

        {/* Mobile render */}
        <LinkButton
          className="text-sm sm:hidden"
          variant="solid"
          palette="accent"
          href="/documents"
        >
          Transfer funds
        </LinkButton>

        {/* Popover contents */}
        <ToggleMoreActions>
          <LinkButton
            className="text-sm sm:hidden"
            variant="outline"
            palette="neutral"
            href="/documents"
          >
            View investments
          </LinkButton>
          <LinkButton
            className="text-sm sm:hidden"
            variant="outline"
            palette="neutral"
            href="/documents"
          >
            View activity
          </LinkButton>
          <LinkButton
            className="text-sm sm:hidden"
            variant="outline"
            palette="neutral"
            href="/documents"
          >
            View documents
          </LinkButton>
          <LinkButton
            className="text-sm"
            variant="outline"
            palette="neutral"
            href="/documents"
          >
            Edit account nickname
          </LinkButton>
          <LinkButton
            className="text-sm"
            variant="outline"
            palette="neutral"
            href="/documents"
          >
            Show account information
          </LinkButton>
          <LinkButton
            className="text-sm"
            variant="outline"
            palette="neutral"
            href="/documents"
          >
            View performance
          </LinkButton>
        </ToggleMoreActions>
      </div>
    </li>
  );
}

function ToggleMoreActions({ children }: { children: React.ReactNode }) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          iconOnly
          variant="outline"
          palette="neutral"
          className="shrink-0"
        >
          <Icon name="ms-more-vert" />
          <span className="sr-only">More actions</span>
        </Button>
      </PopoverTrigger>
      <PopoverPortal>
        <PopoverContent palette="white" size="sm" side="top" className="py-5px">
          <div className="flex flex-col gap-y-5px">{children}</div>
          <PopoverArrow palette="white" />
        </PopoverContent>
      </PopoverPortal>
    </Popover>
  );
}
