import { getCurrentJwt } from "#app/(unauthorized)/authentication/jwt";
import { getBlob, getJson } from "@/lib/fetchClient";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { DocumentMetaData, DocumentsEndpointParams, generateDocumentsQuery } from "./all-documents-api";

export type ClientCommunicationActionUrlParam = {
  key: string;
  value: string;
};

export type ClientCommunicationAlert = {
  actionDescripton: string;
  actionUrlParameters: ClientCommunicationActionUrlParam[];
  actionType: string;
  actionUrl: string;
  cusip: string;
  symbol: string;
  dueDate: Date;
  status: string;
};

export type ClientCommunicationDocument = {
  displayName: string;
  retrievalId: string;
  retrievalType: 0 | 1;
};

export type ClientCommunication = {
  accountNumber: string;
  receivedDate: Date;
  dueDate: string;
  name: string;
  type: string;
  alert: ClientCommunicationAlert;
  documents: ClientCommunicationDocument[];
};

const GATEWAY_PATH = "clientcommunications/v1";

export async function getClientCommunications(
  accounts: string[],
): Promise<ClientCommunication[]> {
  const jwt = getCurrentJwt();
  const accountIndices = jwt.getAccountIndex(accounts);
  const indexes = accountIndices.map((a) => encodeURIComponent(a)).join(",");
  return await getJson<ClientCommunication[]>(
    `/${GATEWAY_PATH}/ClientCommunications?indexes=${indexes}`,
  );
}

export async function getClientCommunicationPdf(key: string): Promise<Blob> {
  return await getBlob(
    `/${GATEWAY_PATH}/ClientCommunications/pdf?key=${encodeURIComponent(key)}`,
  );
}

export async function getClientCommunicationDocuments({accounts, beginDate, endDate}: DocumentsEndpointParams): Promise<DocumentMetaData[]> {
  const paramsString = generateDocumentsQuery({accounts, beginDate, endDate});
  return await getJson<DocumentMetaData[]>(
    `/${GATEWAY_PATH}/ClientCommunications/Documents?${paramsString}`,
  );
}

export const clientCommunicationsQueryKeys = createQueryKeys(
  "ClientCommunications",
  {
    list: (accounts: string[]) => ({
      queryKey: [{ accounts }],
      queryFn: () => getClientCommunications(accounts),
    }),
    singlePdf: (key: string) => ({
      queryKey: [key],
      queryFn: () => getClientCommunicationPdf(key),
    }),
    documents:({accounts, beginDate, endDate}: DocumentsEndpointParams) => ({
      queryKey: [{ accounts, beginDate, endDate }],
      queryFn: () => getClientCommunicationDocuments({accounts, beginDate, endDate}),
    }),
  },
);

export function useClientCommunicationsQuery(accounts: string[]) {
  return useQuery({
    ...clientCommunicationsQueryKeys.list(accounts),
    placeholderData: keepPreviousData,
  });
}

export function useClientCommunicationsDocumentsQuery({accounts, beginDate, endDate }: DocumentsEndpointParams) {
  return useQuery({
    ...clientCommunicationsQueryKeys.documents({accounts, beginDate, endDate}),
    placeholderData: keepPreviousData,
  });
}

export function useClientCommunicationsPdfQuery(key: string) {
  return useQuery(clientCommunicationsQueryKeys.singlePdf(key));
}
