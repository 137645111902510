export type GainLossIconName =
  | "custom-triangle-up"
  | "custom-triangle-down"
  | "custom-not-applicable";
export type GainLossValueStatus = "neutral" | "negative" | "positive";
export type GainLossValueType = "formattable" | "passthrough" | "empty";

export type GainLossValue = string | number | null | undefined;
export type GainLossValueParsed = number;

export type GainLostFormat = keyof typeof gainLostFormatsMap;
export type GainLossLayout = (typeof gainLossLayouts)[number];

export interface DeriveGainLossParams {
  format?: GainLostFormat;
  layout?: GainLossLayout;
  value: GainLossValue;
}

export const gainLostFormatsMap = {
  currency: {
    style: "currency",
    currency: "USD",
    signDisplay: "exceptZero",
  },
  decimal: {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: "exceptZero",
  },
  percent: {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    signDisplay: "exceptZero",
  },
} as const satisfies Record<string, Intl.NumberFormatOptions>;

export const gainLostFormats = Object.keys(
  gainLostFormatsMap,
) as ReadonlyArray<GainLostFormat>;

export const gainLossLayouts = ["iconOnly", "iconAndText", "textOnly"] as const;

export function parseGainLossValue(value: GainLossValue) {
  let numberValue = Number(value);
  const valueParsed = numberValue;

  const isFinite = Number.isFinite(numberValue);
  const isPassthrough = !isFinite && typeof value === "string";
  const isChangeNegative = isFinite && numberValue < 0;
  const isChangePositive = isFinite && numberValue > 0;

  let valueType: GainLossValueType = "empty";
  if (isPassthrough) valueType = "passthrough";
  if (isFinite) valueType = "formattable";

  let valueStatus: GainLossValueStatus = "neutral";
  if (isChangeNegative) valueStatus = "negative";
  if (isChangePositive) valueStatus = "positive";

  return { valueParsed, valueStatus, valueType };
}

export function formatGainLossValue({
  format = "decimal",
  value,
  valueParsed,
  valueType,
}: {
  format?: GainLostFormat;
  value: GainLossValue;
  valueParsed: GainLossValueParsed;
  valueType: GainLossValueType;
}) {
  if (value && valueType === "passthrough") {
    return value;
  }

  if (valueType === "formattable") {
    const options = gainLostFormatsMap[format];
    return new Intl.NumberFormat("en-US", options).format(
      format === "percent" ? valueParsed / 100 : valueParsed,
    );
  }

  return "N/A";
}

export function deriveGainLoss({
  format,
  layout,
  value,
}: DeriveGainLossParams) {
  const { valueParsed, valueStatus, valueType } = parseGainLossValue(value);

  const hasIcon = valueStatus !== "neutral" && layout !== "textOnly";
  const hasText = layout !== "iconOnly";

  let iconName: GainLossIconName = "custom-not-applicable";
  if (hasIcon && valueStatus === "positive") iconName = "custom-triangle-up";
  if (hasIcon && valueStatus === "negative") iconName = "custom-triangle-down";

  const palette = valueStatus;

  let valueFormatted = formatGainLossValue({
    format,
    value,
    valueParsed,
    valueType,
  });
  if (layout !== "textOnly" && valueStatus === "positive") {
    valueFormatted = String(valueFormatted).replace("+", "");
  }

  return {
    hasIcon,
    hasText,
    iconName,
    palette,
    valueFormatted,
    valueParsed,
    valueType,
  };
}
