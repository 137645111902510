import { cva, type VariantProps } from "./style.utils";

// -----------------------------------------------------------------------------

export type CardTabsListVariants = VariantProps<typeof cardTabsListVariants>;

export const cardTabsListVariants = cva({
  base: "flex flex-wrap gap-1px",
  variants: {
    placement: {
      "top-inside": "border-b border-b-shade-10",
      "top-outside": "mx-12px",
    },
  },
  defaultVariants: {
    placement: "top-inside",
  },
});

// -----------------------------------------------------------------------------

export type CardTabsTriggerVariants = VariantProps<
  typeof cardTabsTriggerVariants
>;

export const cardTabsTriggerVariants = cva({
  base: [
    // layout
    "inline-flex h-52px items-center justify-center px-20px",
    // border
    "border-b-[3px] border-b-transparent",
    // background
    "bg-transparent",
    // foreground
    "text-sm leading-none text-shade-100",
    // animation
    "transition-colors",
    // focus/hover/active
    "hover:border-b-link-hover hover:text-link-hover",
    // focus unset outline
    "focus:outline-none",
    // focus reset outline (keyboard only)
    "focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-1 focus-visible:outline-ring",

    // active border
    "data-[state=active]:border-b-link-idle",
    // active foreground
    "data-[state=active]:text-link-idle",
    // active focus/hover/active
    "hover:data-[state=active]:border-b-link-idle hover:data-[state=active]:text-link-idle",
  ],
});
