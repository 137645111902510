import { type IDataGridOptions } from "devextreme-react/data-grid";
import { Span, SpanProps } from "./typography";

// -----------------------------------------------------------------------------

// Spaced away from normal imports to avoid editor/formattor auto-sorting.

// Base styles. Must import first!
import "./data-grid.styles.css";
// Overrides cascade. Must import last!
import "./data-grid.styles.overrides.css";
import { cx } from "./style.utils";

// -----------------------------------------------------------------------------

/*
The `satisfies IDataGridOptions` typechecks below
are unaware of each table's specific type requirements,
but still helpful here to avoid obvious configuration mistakes.
*/

/**
 * Shared `DataGrid` props.
 * See `/ui/data-grid.docs.md` for usage.
 */
export const defaultDataGridProps = {
  // pagination props
  pager: {
    displayMode: "adaptive",
    showNavigationButtons: true,
  },
  paging: {
    pageSize: 10,
  },

  // responsive props
  columnAutoWidth: true,
  columnHidingEnabled: true,

  // style props
  showBorders: false,
  showColumnLines: false,
  showRowLines: true,
} as const satisfies IDataGridOptions;

/**
 * Shared `DataGrid` props with row selection enabled.
 * See `/ui/data-grid.docs.md` for usage.
 */
export const defaultDataGridWithMultiSelectProps = {
  ...defaultDataGridProps,
  // row selection props
  selection: {
    mode: "multiple",
    selectAllMode: "allPages",
  },
} as const satisfies IDataGridOptions;

// -----------------------------------------------------------------------------

// Re-exporting only. Composing/configuring will destroy type-hinting/safety.
export {
  Column,
  DataGrid,
  Position,
  type DataGridRef,
  type DataGridTypes,
} from "devextreme-react/data-grid";

// ...also a non-react related types needed.
export { type CellPreparedEvent } from "devextreme/ui/data_grid";

// -----------------------------------------------------------------------------

export function CellUnavailable({
  children = "--",
  className,
  ...props
}: SpanProps) {
  return (
    <Span
      {...props}
      aria-hidden="true"
      className={cx("text-shade-40", className)}
    >
      {children}
    </Span>
  );
}
