import { forwardRef } from "react";
import { gainLossVariants, type GainLossVariants } from "./gain-loss.styles";
import { deriveGainLoss, type DeriveGainLossParams } from "./gain-loss.utils";
import { Icon } from "./icon";

export type GainLossRef = React.ElementRef<"span">;

export interface GainLossProps
  extends Omit<React.ComponentPropsWithoutRef<"span">, "children">,
    Omit<GainLossVariants, "palette">,
    DeriveGainLossParams {}

const GainLoss = forwardRef<GainLossRef, GainLossProps>(
  (
    { className, format = "decimal", layout = "iconAndText", value, ...props },
    ref,
  ) => {
    const { hasIcon, iconName, palette, valueFormatted } = deriveGainLoss({
      format,
      layout,
      value,
    });

    return (
      <span
        {...props}
        className={gainLossVariants({ className, palette })}
        ref={ref}
      >
        {layout === "textOnly" ? (
          valueFormatted
        ) : layout === "iconOnly" ? (
          <>
            <Icon
              name={iconName}
              className={palette === "neutral" ? "opacity-20" : undefined}
            />
            <span className="sr-only">{valueFormatted}</span>
          </>
        ) : (
          <>
            {hasIcon ? <Icon name={iconName} /> : null}
            <span>{valueFormatted}</span>
          </>
        )}
      </span>
    );
  },
);

GainLoss.displayName = "GainLoss";

export { GainLoss };
