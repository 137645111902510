"use client";

import { AnchorSecuritySymbol } from "#app/(secured)/documents/_shared/AnchorSecuritySymbol";
import {
  portfolioQueryKeys,
  type AccountActivity,
} from "#app/_api/portfolio-service";
import { Card, CardContent, CardHeader, type CardRootProps } from "#ui/card";
import {
  CellUnavailable,
  Column,
  DataGrid,
  defaultDataGridProps,
} from "#ui/data-grid";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { H2, P } from "#ui/typography";
import { useQuery } from "@tanstack/react-query";
import Image from "next/image";
import { ErrorCardContent } from "../cards/ErrorCard";
import { PendingCardContent } from "../cards/PendingCard";
import { Account } from "../textFormaters/Account";

/**
 * Only show for Clients, 3rd Parties, PAM
 * Visibility handled by different dashboard routes.
 */
export function CardRecentActivity(props: CardRootProps) {
  const { data, isError, isPending, isSuccess } = useQuery({
    ...portfolioQueryKeys.recentActivity({
      activitySortValue: "Date",
      sortDirection: "Descending",
    }),
    // TODO: how long? staleTime: 5 * 60 * 1000, // 5min
  });

  const isEmpty = isSuccess && data.transactions.length === 0;
  const isFound = isSuccess && data.transactions.length > 0;
  const recentActivity = data?.transactions.slice(0, 5) ?? [];

  return (
    <Card {...props}>
      <CardHeader className="flex items-center justify-between">
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-list" size="baird-md" />
          <span>Recent Activity</span>
        </H2>
        <LinkButton href="/investments/activity" variant="link">
          <span>See all</span>
          <Icon name="ms-navigate-next" />
        </LinkButton>
      </CardHeader>
      <CardContent
        className={
          isEmpty
            ? "pb-50px pt-[42px] sm:pt-32px"
            : isFound
              ? "px-0 pb-0"
              : undefined
        }
      >
        {isPending ? <PendingCardContent className="md:min-h-fit" /> : null}
        {isError ? <ErrorCardContent className="md:min-h-fit" /> : null}
        {isEmpty ? <CardRecentActivityEmpty /> : null}
        {isFound ? <CardRecentActivityFound data={recentActivity} /> : null}
      </CardContent>
    </Card>
  );
}

function CardRecentActivityEmpty() {
  return (
    <div className="space-y-30px text-center">
      <Image
        alt=""
        src="/Images/image-empty-state.png"
        priority
        width="211"
        height="171"
        className="mx-auto max-sm:w-[148px]"
      />
      <P size="h6">You have no recent activity.</P>
    </div>
  );
}

function CardRecentActivityFound({
  data,
}: {
  data: AccountActivity["transactions"];
}) {
  return (
    <div className="overflow-clip rounded-b-xl border-t border-t-shade-15">
      <DataGrid
        {...defaultDataGridProps}
        dataSource={data}
        noDataText="No results found"
      >
        <Column
          name="date"
          caption="Date"
          dataField="tradeDate"
          dataType="date"
          defaultSortOrder="desc"
          format="LLL d, yyyy"
        />
        <Column
          name="description"
          caption="Description"
          dataField="desc"
          dataType="string"
          width={140}
        />
        <Column
          name="securityId"
          caption="Security ID"
          dataField="instrument.symbol"
          dataType="string"
          cellRender={(templateData: { data: (typeof data)[number] }) =>
            templateData.data.instrument?.symbol ? (
              <AnchorSecuritySymbol
                symbol={templateData.data.instrument.symbol}
              />
            ) : (
              <CellUnavailable />
            )
          }
        />
        <Column
          name="account"
          caption="Account"
          dataField="accountNumber"
          dataType="number"
          cellRender={(templateData: { data: (typeof data)[number] }) => (
            <Account accountNumber={templateData.data.accountNumber} />
          )}
          alignment="left"
        />
        <Column
          name="amount"
          caption="Amount"
          dataField="quantity"
          dataType="number"
          cellRender={(templateData: { data: (typeof data)[number] }) =>
            formatQuantity(templateData.data.quantity) ?? <CellUnavailable />
          }
          alignment="right"
        />
        <Column
          name="price"
          caption="Price"
          dataField="price"
          dataType="number"
          cellRender={(templateData: { data: (typeof data)[number] }) =>
            formatCurrency(templateData.data.price) ?? <CellUnavailable />
          }
          format="$,##0.00"
          alignment="right"
        />
        <Column
          name="netAmount"
          caption="Net Amount"
          dataField="amount"
          dataType="number"
          cellRender={(templateData: { data: (typeof data)[number] }) =>
            formatCurrency(templateData.data.amount) ?? <CellUnavailable />
          }
          alignment="right"
        />
      </DataGrid>
    </div>
  );
}

// Stablize constructors for performance
const IntlNumber = new Intl.NumberFormat("en-US");
const IntlCurrency = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

function formatQuantity(value: number) {
  const val = formatNumberWithoutRounding(value, 4, true);
  return val === "0" ? null : val;
}

function formatCurrency(value: number) {
  const val = IntlCurrency.format(value);
  return val === "$0.00" ? null : val;
}

/**
 * Formats similar to Intl.NumberFormat("en-US"):
 * - commas on larger significant digits
 * - limits maximum fraction digits (default: 0)
 * - no rounding on fraction digits
 * - no trailing zeros on fraction digits (optional, default: false)
 *
 * Usage:
 * - `formatNumberWithoutRounding(1234.0); // => "1,234"`
 * - `formatNumberWithoutRounding(1234.90); // => "1,234"`
 * - `formatNumberWithoutRounding(1234.90909); // => "1,234"`
 * - `formatNumberWithoutRounding(1234.99999); // => "1,234"`
 *
 * Usage with `maximumFractionDigits` set:
 * - `formatNumberWithoutRounding(1234.0, 4); // => "1,234"`
 * - `formatNumberWithoutRounding(1234.90, 4); // => "1,234.9000"`
 * - `formatNumberWithoutRounding(1234.90909, 4); // => "1,234.9090"`
 * - `formatNumberWithoutRounding(1234.99999, 4); // => "1,234.9999"`
 *
 * Usage with `maximumFractionDigits` and `stripTrailingZeros` set:
 * - `formatNumberWithoutRounding(1234.0, 4, true); // => "1,234"`
 * - `formatNumberWithoutRounding(1234.90, 4, true); // => "1,234.9"`
 * - `formatNumberWithoutRounding(1234.90909, 4, true); // => "1,234.909"`
 * - `formatNumberWithoutRounding(1234.99999, 4, true); // => "1,234.9999"`
 */
function formatNumberWithoutRounding(
  value: number | unknown,
  maximumFractionDigits = 0,
  stripTrailingZeros = false,
) {
  if (typeof value !== "number") {
    return "";
  }
  // format floats
  if (value % 1 !== 0) {
    const [a, b] = String(value).split(".");
    const aFormatted = IntlNumber.format(Number(a));
    let bFormatted: string | null = b?.slice(0, maximumFractionDigits) ?? null;
    if (bFormatted && maximumFractionDigits > 0) {
      bFormatted = bFormatted.padEnd(maximumFractionDigits, "0");
    }
    if (bFormatted && stripTrailingZeros) {
      bFormatted = bFormatted.replace(/([1-9])0+$/g, "$1");
    }
    return [aFormatted, bFormatted].filter(Boolean).join(".");
  }
  // format integers
  return IntlNumber.format(value);
}
