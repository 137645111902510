import {
  dialogContentVariants,
  dialogDescriptionVariants,
  dialogFooterVariants,
  dialogHeaderVariants,
  dialogMainVariants,
  dialogOverlayVariants,
  dialogTitleVariants,
  type DialogContentVariants,
  type DialogDescriptionVariants,
  type DialogFooterVariants,
  type DialogHeaderVariants,
  type DialogMainVariants,
  type DialogOverlayVariants,
  type DialogTitleVariants,
} from "./dialog.styles";

// -----------------------------------------------------------------------------

export type AlertDialogOverlayVariants = DialogOverlayVariants;

export const alertDialogOverlayVariants = dialogOverlayVariants;

// -----------------------------------------------------------------------------

export type AlertDialogContentVariants = DialogContentVariants;

export const alertDialogContentVariants = dialogContentVariants;

// -----------------------------------------------------------------------------

export type AlertDialogHeaderVariants = DialogHeaderVariants;

export const alertDialogHeaderVariants = dialogHeaderVariants;

// -----------------------------------------------------------------------------

export type AlertDialogMainVariants = DialogMainVariants;

export const alertDialogMainVariants = dialogMainVariants;

// -----------------------------------------------------------------------------

export type AlertDialogFooterVariants = DialogFooterVariants;

export const alertDialogFooterVariants = dialogFooterVariants;

// -----------------------------------------------------------------------------

export type AlertDialogTitleVariants = DialogTitleVariants;

export const alertDialogTitleVariants = dialogTitleVariants;

// -----------------------------------------------------------------------------

export type AlertDialogDescriptionVariants = DialogDescriptionVariants;

export const alertDialogDescriptionVariants = dialogDescriptionVariants;
