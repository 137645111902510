"use client";

import { convertPortfolioToVm } from "#app/(secured)/investments/performance/conversions";
import { PortfolioGraph } from "#app/(secured)/investments/performance/PortfolioGraph";
import { PortfolioSummary } from "#app/(secured)/investments/performance/PortfolioSummary";
import * as performanceReportsService from "#app/_api/performance-reports-service";
import { Card, CardContent, CardHeader, type CardRootProps } from "#ui/card";
import { Icon } from "#ui/icon";
import { LinkButton } from "#ui/link";
import { H2 } from "#ui/typography";

export function CardPortfolioPerformance(props: CardRootProps) {
  const { data } = performanceReportsService.usePortfolioPerformance(
    false,
    false,
    undefined,
  );
  const portfolioData =
    data !== undefined ? convertPortfolioToVm(data) : undefined;
  return (
    <Card {...props}>
      <CardHeader className="flex items-center justify-between">
        <H2 size="h6" className="flex items-center gap-10px">
          <Icon name="baird-bar-chart-skinny" size="baird-md" />
          <span>Portfolio Performance</span>
        </H2>
        <LinkButton variant="link" href="/investments/performance">
          <span>See details</span>
          <Icon name="ms-navigate-next" />
        </LinkButton>
      </CardHeader>
      <CardContent>
        <div className="grid gap-30px md:grid-cols-2">
          <div className="-mt-8px">
            <PortfolioGraph
              portfolioData={portfolioData}
              asOfLastBusDay={false}
              height={273}
            />
          </div>
          <div className="rounded-xl border border-shade-10">
            <PortfolioSummary
              portfolioData={portfolioData}
              asOfLastBusDay={false}
              className="grid h-full *:px-20px"
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
